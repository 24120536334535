import React from "react"
import heroImg from "../images/kv-2x.png"

import {Button} from "./button"

const Hero = () => (
    <section className="hero"> 
        <div className="inner-content">
            <div className="hero-text">
                <h1>Social Care Automation for Community Health</h1>
                <p className="paragraph-text">Rapid access to government benefits and community-based social services.</p>
                <Button buttonText="Request Free Demo" />
            </div>
            <img src={heroImg} alt="Hero Image" />
        </div>
    </section>
)

export default Hero;