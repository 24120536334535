import React from "react"

const Solution = ({ title, metric, titleExtension, description, img, textPosition }) => (
  <div className="solution">
    <div className={`solution-description ${textPosition}`}>
      <h2>{title}</h2>
      <p className="highlight-text metric-text">{metric}</p>
      <h3>{titleExtension}</h3>
      <p className="body-text">{description}</p>
    </div>
    <img src={img} alt="Solution Image" />
  </div>
)

export default Solution
