import React from "react"
import heartIcon from "../images/icon-heart.svg"
import growthIcon from "../images/icon-growth.svg"
import connectIcon from "../images/icon-connect.svg"
import insightIcon from "../images/icon-insight.svg"
import profitIcon from "../images/icon-profit.svg"

import {Button} from "./button"
import Product from "./product"

const Products = () => {
    const cardData = [
        {
            key: "heart",
            icon: heartIcon,
            title: "Community Care Network",
            subtitle: "An active network of partners collaborating to deliver community services",
            description: "We enhance social care management with our extensive network and smart matching tools.",
        },
        {
            key: "growth",
            icon: growthIcon,
            title: "Government Links",
            subtitle: "Automation-driven enrollment gateway for government benefits",
            description: "We use automation to streamline enrollment into government benefits such as Medicaid, SNAP, and cash assistance.",
        },
        {
            key: "connect",
            icon: connectIcon,
            title: "Allied Benefits Portal",
            subtitle: "A one-stop shop to track all your social service benefits at your fingertips",
            description: "Our consumer gateway makes it easy to access key information about all your social services in one place.",
        },
        {
            key: "insight",
            icon: insightIcon,
            title: "Active Insights",
            subtitle: "Predictive analytics and strategic social care management",
            description: "Predictive modeling to identify and manage high-risk patients based on unique patient level data.",
        },
        {
            key: "profit",
            icon: profitIcon,
            title: "Active Payments",
            subtitle: "Value-based payment management through social care automation",
            description: "We help your organization deploy value-based payments strategies for social care management.",
        },
    ]

    const cards = cardData.map((product, index) => {
        const { key, icon, title, subtitle, description } = product;

        return (
            <Product
                icon={icon}
                key={key}
                title={title}
                subtitle={subtitle}
                description={description}
                id={index + 1}
            />
        )
    })

    return (
        <section className="products">
            <h2>With people at the center,<br/>social service gets personal at scale</h2>
            <div className="product-cards">
                {cards}
            </div>
            <Button buttonText="Request Free Demo"/>
        </section>
    )
}

export default Products;