import React from "react"

import linkedinLogo from "../images/icon-linkedin.svg"

const Teammate = ({ img, name, role, bio, linkedIn }) => (
    <div className="teammate">
        <img src={img} alt="Teammate Image" />
        <h4 className="teammate-name">{name}</h4>
        <h4 className="teammate-role">{role}</h4>
        <p className="body-text">{bio}</p>
        <a href={linkedIn}>
            <img src={linkedinLogo} alt="Teammate LinkedIn" />
        </a>
    </div>
)

export default Teammate;