import React from "react"

import Layout, { TopCTAStateContext } from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Services from "../components/services"
import FeaturedBanner from "../components/featuredBanner"
import Solutions from "../components/solutions"
import Products from "../components/products"
import Team from "../components/team"
import Contact from "../components/contact"

const IndexPage = () => (
    <Layout>
        <SEO title="CareAdvisors | Social Care Automation for Community Health" />
        <Hero/>
        <Services/>
        <FeaturedBanner/>
        <Solutions/>
        <Products/>
        <Team/>
        <Contact/>
    </Layout>
)

export default IndexPage
