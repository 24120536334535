import { Link } from "gatsby"
import React from "react"

const Industry = ({ icon, title, text, buttonText }) => {
  let route = null

  switch (buttonText) {
    case "Request Free Demo":
      route = "/request"
      break
    case "Join The Network":
      route = "/join"
      break
    default:
      break
  }

  return (
    <div className="industry">
      <img src={icon} alt="Industry Icon" />
      <h4>{title}</h4>
      <hr />
      <p className="body-text">{text}</p>
      <Link to={route ? route : null}>
        <button>
          {buttonText}&nbsp;&nbsp;&nbsp;
          <svg
            width="7"
            height="10"
            viewBox="0 0 7 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.63917 10C1.09917 9.42417 0.559167 8.84792 0.00375 8.25542C1.04417 7.21584 2.14625 6.11459 3.26167 5C2.14625 3.88542 1.04375 2.78417 0 1.74125C0.556667 1.14958 1.09792 0.575 1.63917 0C1.65292 0 1.66708 0 1.68083 0C1.7275 0.0579167 1.77 0.120417 1.82208 0.172917C3.35417 1.7225 4.8875 3.27084 6.42042 4.82C6.47375 4.87375 6.52542 4.92917 6.5925 4.99959C6.53667 5.05834 6.48667 5.11292 6.435 5.16542C4.90208 6.71417 3.36875 8.26292 1.83667 9.81251C1.77958 9.87001 1.7325 9.93751 1.68083 10.0004C1.66667 10 1.65292 10 1.63917 10Z"
              fill="#068BB7"
            />
          </svg>
        </button>
      </Link>
    </div>
  )
}

export default Industry
