import React from "react";
import { graphql } from 'gatsby';
import styles from './insights.module.scss';

import Layout from "../components/layout";
import SEO from "../components/seo";
import InnerPageHero from "../components/innerPageHero";
import { InsightCard } from "../components/cards";
import Contact from "../components/contact";

import heroImg from "../images/insights-hero.jpg";

const InsightsPage = ({ data }) => (
  <Layout>
    <SEO title="CareAdvisors | Social Care Automation for Community Health" />
    <InnerPageHero
      title="Insights"
      subtitle="Our thought leaders share their unique perspectives and expertise"
      heroImg={heroImg}
      color="blue"
    />
    <div className="container">

      <div className={styles.insights}>
        {
          data.allWpInsight.nodes
            .sort(
              (left, right) => (
                Date.parse(right.insightData.publishedDate) - Date.parse(left.insightData.publishedDate)
              )
            )
            .map((insight) => {
            return (
              <InsightCard
                key={insight.id}
                title={insight.title}
                buttonText={insight.insightData.ctaText}
                isGated={insight.insightData.isGated}
                image={insight.featuredImage ? insight.featuredImage.node : ''}
                date={insight.insightData.publishedDate}
                introCopy={insight.insightData.introCopy}
                format={insight.insightData.format}
                typeOfMedia={insight.insightData.typeOfMedia}
                insightUrl={getInsightLink(insight)}

              />
            )
          })
        }
      </div>

    </div>
    <Contact />
  </Layout>
)

export const getInsightLink = (data) => {

  let linkUrl = ''

  if (!data.insightData.isGated && data.insightData.typeOfMedia === 'video') {
    return `/insights/video/${data.databaseId}`;
  } else if (!data.insightData.isGated && data.insightData.typeOfMedia === 'pdf') {
    return data.insightData.pdfUrl.mediaItemUrl;
  }

  if (data.insightData.isGated) {
    return linkUrl = `/insights/${data.id}`;
  }
}

export const data = graphql`
query InsightsQuery {
    allWpInsight {
      nodes {
        id
        databaseId
        date
        title
        content
        featuredImage {
            node {
              srcSet
              sizes
            }
        }
        insightData {
          ctaText
          format
          hubspotPortalId
          hubspotFormId
          introCopy
          isGated
          typeOfMedia
          videoUrl
          publishedDate
          pdfUrl {
            mediaItemUrl
          }
          presenters {
            photo {
              sourceUrl
            }
            subtitle
            title
          }
        }
      }
    }
  }  
`

export default InsightsPage
