import React from "react"

const Product = ({ icon, title, subtitle, description, id }) => (
    <div className="solution" id={`solution-item${id}`}>
        <img src={icon} alt="Product Image" />
        <h3>{title}</h3>
        <p className="paragraph-text">{subtitle}</p>
        <p className="body-text">{description}</p>
    </div>
)

export default Product;