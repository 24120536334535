import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import sanitizeHtml from 'sanitize-html';

import { CmsButton } from "./button";
import { getShortDate, getLongDate, getOrdinal } from "./cards";
import {getInsightLink} from '../pages/insights';

import styles from './featuredBanner.module.scss';

const GetBannerContent = () => {
    const bannerContent = useStaticQuery(graphql`
    query FeaturedBanner {
        allWp {
            nodes {
            siteOptions {
                wpSiteOptions {
                homepageBanner {
                    displayFeaturedBanner
                    ctaLinkUrl
                    ctaText
                    description
                    eyebrow
                    fieldGroupName
                    title
                    typeOfBanner
                    featuredEvent {
                    ... on WpEvent {
                        id
                        title
                        content
                        featuredImage {
                        node {
                            sizes
                            srcSet
                        }
                        }
                        eventData {
                        ctaLink
                        ctaText
                        eventDate
                        eventEndTime
                        eventLocationName
                        eventTime
                        eventTimezone
                        }
                    }
                    }
                    featuredInsight {
                    ... on WpInsight {
                        id
                        title
                        featuredImage {
                            node {
                                sizes
                                srcSet
                            }
                        }
                        content
                        insightData {
                            typeOfMedia
                            ctaText
                            hubspotFormId
                            hubspotPortalId
                            introCopy
                            isGated
                            pdfUrl {
                                mediaItemUrl
                            }
                        }
                    }
                    }
                    thumbnail {
                        sizes
                        srcSet
                    }
                }
                }
            }
            }
        }
    }`)

    return bannerContent.allWp.nodes[0].siteOptions.wpSiteOptions.homepageBanner;
}

const getTitle = () => {

    if (GetBannerContent().typeOfBanner === 'custom') {
        return GetBannerContent().title;
    } else if (GetBannerContent().typeOfBanner === 'event') {
        return GetBannerContent().featuredEvent[0].title;
    } else if (GetBannerContent().typeOfBanner === 'insight') {
        return GetBannerContent().featuredInsight[0].title;
    }

};

const getImage = () => {

    if (GetBannerContent().typeOfBanner === 'custom') {
        return GetBannerContent().thumbnail;
    } else if (GetBannerContent().typeOfBanner === 'event') {
        return GetBannerContent().featuredEvent[0].featuredImage.node;
    } else if (GetBannerContent().typeOfBanner === 'insight') {
        return GetBannerContent().featuredInsight[0].featuredImage.node;
    }

};

const getDescription = () => {
    if (GetBannerContent().typeOfBanner === 'custom') {
        return GetBannerContent().content;
    } else if (GetBannerContent().typeOfBanner === 'event') {
        return GetBannerContent().featuredEvent[0].content;
    } else if (GetBannerContent().typeOfBanner === 'insight') {
        return GetBannerContent().featuredInsight[0].insightData.introCopy;
    }
};

const getCtaButtonContent = () => {
    if (GetBannerContent().typeOfBanner === 'custom') {
        return {
            linkUrl: GetBannerContent().ctaLinkUrl,
            buttonText: GetBannerContent().ctaText 
        };
    } else if (GetBannerContent().typeOfBanner === 'event') {
        return {
            linkUrl: GetBannerContent().featuredEvent[0].eventData.ctaLink,
            buttonText: GetBannerContent().featuredEvent[0].eventData.ctaText 
        };
    } else if (GetBannerContent().typeOfBanner === 'insight') {
        return {
            linkUrl: getInsightLink(GetBannerContent().featuredInsight[0]),
            buttonText: GetBannerContent().featuredInsight[0].insightData.ctaText 
        };
    }
};

const FeaturedBanner = () => {
    if(GetBannerContent().displayFeaturedBanner) {
        return (

            <section className={styles.featuredBannerBackground}>
        
                <div className="container">
                    <section className={styles.featuredBanner}>
        
                        {getImage() && <div className={styles.imageContent}>
                            <span></span>
                            <span></span>
                            <span></span>
                                <div className={styles.imageContainer}>
                                    <img
                                    srcSet={getImage().srcSet}
                                    sizes="(min-width: 2048px) 2048px, (min-width: 1536px) 1536px, (min-width: 1024px) 1024px, (min-width: 768px) 768px, 100vw"
                                    alt={ getTitle() } />
                                </div>
                        </div>}
        
                        <div className={styles.textContent}>
                            <div>
                                <p className={styles.bannerEyebrow}>{ GetBannerContent().eyebrow }</p>
                                <h2 className={styles.bannerTitle}>{ getTitle() }</h2>
        
                                {
                                    <div
                                    className={styles.bannerBody}
                                    dangerouslySetInnerHTML={
                                        {__html: sanitizeHtml(getDescription(), {
                                            allowedTags: ['div', 'h1', 'h2', 'h3', 'p', 'b', 'i', 'ul', 'li'],
                                        })}
                                    }>
                                    </div>
                                }
        
                                {
                                    GetBannerContent().typeOfBanner === 'event' &&
                                    <>
                                        <p className={styles.eventDate}>
                                            <span className={styles.month}>
                                            { getLongDate(GetBannerContent().featuredEvent[0].eventData.eventDate).month }
                                            </span> <span className={styles.date}> 
                                            { getLongDate(GetBannerContent().featuredEvent[0].eventData.eventDate).date }, { getLongDate(GetBannerContent().featuredEvent[0].eventData.eventDate).year }
                                            </span>
                                        </p>
                                        
                                        <p className={styles.time}>
                                        { GetBannerContent().featuredEvent[0].eventData.eventTime }
                                        { GetBannerContent().featuredEvent[0].eventData.eventEndTime && <span> - {GetBannerContent().featuredEvent[0].eventData.eventEndTime }</span> } { GetBannerContent().featuredEvent[0].eventData.eventTimezone } 
                                        </p>
                                    </>
                                }
        
                            </div>
                            <div className={styles.bannerCta}>
                                <CmsButton buttonText={getCtaButtonContent().buttonText} linkUrl={getCtaButtonContent().linkUrl} />
                            </div>
                        </div>
                    </section>
                </div>
                <div className={styles.aquaBackground}></div>
            </section>
        )
    } else {
        return null;
    }
}

export default FeaturedBanner;