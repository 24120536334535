import React from "react"
import hospitalIcon from "../images/icon-hospital.svg"
import healthPlanIcon from "../images/icon-healthplan.svg"
import cboIcon from "../images/icon-cbo.svg"
import socialWorkersIcon from "../images/icon-social workers.svg"

import Industry from "./industry"

const Services = () => {
  const cardData = [
    {
      key: "hsp",
      title: "Hospitals",
      icon: hospitalIcon,
      text:
        "We help hospitals and clinical social workers connect patients to the best resources and rapidly enroll them in benefits to address social care needs. Our Social Care Automation tool also enables hospitals to generate revenue from preventive health programs.",
      buttonText: "Request Free Demo",
    },
    {
      key: "plans",
      icon: healthPlanIcon,
      title: "Health Plans",
      text:
        "Our Social Care Automation technology helps health plans reduce overutilization and puts the focus on preventive care. We also offer automated support to quickly enroll new members and keep existing members in coverage to maintain the continuity of care.",
      buttonText: "Request Free Demo",
    },
    {
      key: "cbo",
      icon: cboIcon,
      title: "Community Based Organizations (CBOs)",
      text:
        "CBOs are vital in addressing the social care needs for vulnerable populations locally, and our technology makes it easier for organizations to deliver their specialized services. Join our extensive network of CBOs and start working with leading healthcare organizations.",
      buttonText: "Join The Network",
    },
    {
      key: "sw",
      icon: socialWorkersIcon,
      title: "Social Workers",
      text:
        "Clinical social workers play a key role in identifying the social needs of patients and connecting them to the most appropriate resources. Our Social Worker Forum is a place to connect with other clinical social workers locally to share best practices and learnings.",
      buttonText: "Join The Network",
    },
  ]

  const cards = cardData.map(industry => {
    const { key, icon, title, text, buttonText } = industry

    return (
      <Industry
        className="industry"
        key={key}
        icon={icon}
        title={title}
        text={text}
        buttonText={buttonText}
      />
    )
  })

  return (
    <section className="services">
      <div className="inner-content">
        <h2>Built to empower every team</h2>
        <div className="industries">{cards}</div>
      </div>
    </section>
  )
}

export default Services
